import { captureException } from '@sentry/browser';

const webkitMessageHandler = (provider: string) => {
  try {
    window?.webkit?.messageHandlers?.CameraFiStudio?.postMessage(
      JSON.stringify({
        method: 'sso',
        params: { provider },
      }),
    );
  } catch (err) {
    captureException(err);
  }
};

export { webkitMessageHandler };
