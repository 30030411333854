const debounce = (func: () => void, delay: number) => {
  let timerId: number;

  const debounced = () => {
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      func();
    }, delay);
  };

  const cancel = () => {
    clearTimeout(timerId);
  };

  debounced.cancel = cancel;

  return debounced;
};

export default debounce;
